import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Parallax, ParallaxProvider, useParallax} from 'react-scroll-parallax';
import './styles.css';
import {FaArrowCircleUp, FaBullseye, FaCertificate, FaLightbulb, FaStar} from 'react-icons/fa';

const Competencies = () => {


    return (
        <div className='pt-5 p-2 mb-5' id='about'>

    
                    <Container className='abtcon p-5 mb-2'>
                        <Row>
                            <Col lg={12}
                                xs={12}>
                                <div>
                                    <Row>
                                        <Col lg={1}
                                            xs={2}><FaLightbulb className='' color='#916f5d'
                                                size={35}/></Col>
                                        <Col>
                                            <h4 className="dark-color2 a-left">Core Competencies</h4>
                                        </Col>
                                    </Row>


                                    <ul className='a-left'>
                                        <li>Strategy, Leadership, Execution</li>
                                        <li>Performance Improvement</li>
                                        <li>Managing teams in multicultural environments</li>
                                        <li>Transformation & Championing Change Management</li>
                                        <li>Technology Sourcing</li>
                                        <li>Negotiation & Vendor Management</li>
                                        <li>Technology Risk Management</li>
                                        <li>Program Management</li>
                                        <li>Quality & Continuous Improvement</li>
                                        <li>Investor/ Owner relationship management</li>
                                        <li>IT governance, policies, and procedures development</li>
                                    </ul>


                                </div>
                            </Col>


                        </Row>
                    </Container>
               

        </div>
    )
}

export default Competencies

import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Parallax, ParallaxProvider, useParallax} from 'react-scroll-parallax';
import './styles.css';
import {FaArrowCircleUp, FaBullseye, FaCertificate, FaStar} from 'react-icons/fa';
const Expertise = () => {


    return (
        <div className='pt-2 pb-2' id='expertise'>

                    <Container className='p-0'>
                        <Row>
                            <Col className='p-3'>
                                <div className='xpcon p-5 mt-3'>
                                    <Row>
                                        <Col lg={12}
                                            xs={12}>
                                            <div>
                                                <Row>
                                                    <Col lg={1}
                                                        xs={2}><FaStar className=''  color='#916f5d'
                                                            size={35}/></Col>
                                                    <Col>
                                                        <h4 className="dark-color2 a-left">Expertise in Brief</h4>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row className='a-left pl-25 pb-2'>
                                                    <Col lg={8}
                                                        xs={9}>Business installation, Re-structuring, Technology Implementations
                                                    </Col>
                                                    <Col>
                                                        07 years</Col>
                                                </Row>
                                                <hr/>
                                                <Row className='a-left pl-25 pb-2'>
                                                    <Col lg={8}
                                                        xs={9}>System planning, Architecture Development, Service Management</Col>
                                                    <Col>
                                                        06 years</Col>
                                                </Row>
                                                <hr/>
                                                <Row className='a-left pl-25 pb-5'>
                                                    <Col lg={8}
                                                        xs={9}>Project Management, Team Building, Digital Transformation
                                                    </Col>
                                                    <Col>
                                                        12 years</Col>
                                                </Row>

                                            </div>
                                        </Col>


                                    </Row>
                                </div>
                            </Col>
                            <Col className='p-3'>
                                <div className='xpcon p-5 mt-3'>
                                    <Row>
                                        <Col lg={12}
                                            xs={12}>
                                            <div>
                                                <Row>
                                                    <Col lg={1}
                                                        xs={2}><FaCertificate className='' color='#916f5d'
                                                            size={35}/></Col>
                                                    <Col>
                                                        <h4 className="dark-color2 a-left">Certifications</h4>
                                                    </Col>
                                                </Row>


                                                <ul className='a-left mt-3'>
                                                    <li>ITIL V4 ( Service Management ) Certification from Axelos</li>
                                                    <li>IT Management Academy Part I and II (CPE for HFTP & ISACA)</li>
                                                    <li>Finance Academy & Rational and dynamic pricing Training</li>
                                                    <li>Leading Starwood – Leadership Training</li>
                                                    <li>Hot Academy Training for hotel pre-opening</li>
                                                    <li>Starwood Hotels and Resorts Brand Training</li>
                                                </ul>


                                            </div>
                                        </Col>


                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
              

        </div>
    )
}

export default Expertise

import logo from './logo.svg';
import './App.css';
import Section1 from './components/Section1';
import Objectives from './components/Objectives';
import NavBar from './components/NavBar';
import Profile from './components/Profile';
import Expertise from './components/Expertise';
import { FaCertificate } from 'react-icons/fa';
import Competencies from './components/Competencies';
import Accomplishments from './components/Accomplishments';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Section1/>
      <Objectives/>
      <Expertise/>
      <Accomplishments/>
      <Competencies/>
      
      <Footer/>
    </div>
  );
}

export default App;

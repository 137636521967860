import React, {useEffect, useState, useRef, useMemo} from "react";
import {Button, Col, Row} from "react-bootstrap";
import Check from "../../components/Check";
import Loading from "../../components/Loading";
import Navbars from "../../components/NavBar";
import firebase from "../../firebase";
import "./arrivals.css";
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';

const AddBlogs = () => {
    const [arrivals, setArrivals] = useState([]);
    const [image, setImage] = useState(null);
    const [transfer, setTransfer] = useState();
    const [loading, setLoading] = useState();
    const [head, setHead] = useState();

    const editor = useRef(null);
    const [content, setContent] = useState('');


    useEffect(() => {
        fetchArrivals();
    }, []);

    const fetchArrivals = async () => {
        try {
            var datas = [];
            var snapshot = await firebase.firestore().collection("accomplishments").orderBy('date' ,'desc').get();
            snapshot.forEach((doc) => {
                datas.push(doc.data());
            });
            setArrivals([... datas]);
        } catch (err) {
            console.log(err);
        }
    };

    const removeProduct = async (uid, url, cat) => {
        if (window.confirm("Delete the accomplishment ?")) {
            try {
                setLoading(true);
               
                    firebase.firestore().collection("accomplishments").doc(uid).delete().then((res) => {
                        alert("Accomplishment removed successfully !");
                        window.location.reload();
                    
                });
            } catch (err) {
                console.log(err);
            }
        }
    };

    const upload = async () => {
        if (content != "" ) {
            let date = new Date();
            date = date.toString();
            var ndate = new Date();
           
            setLoading(true);
            
           
            try {
                
                        const docRef = firebase.firestore().collection("accomplishments").doc();
                        const docId = docRef.id;

                        docRef.set({uid: docId, content: content,date:ndate}).then(function () {
                            alert("Added !");
                            window.location.reload();
                        });
                   
            } catch (err) {
                console.log(err);
            }
            setImage("");
        } else {
            alert("Please fill out all fields !");
        }
    };


    return (
        <div>
            <Check/> {
            loading == true ? (
                <Loading/>) : (
                <div>
                    <Navbars active={"link-1"}/>
                    <hr/>
                    <div className="container obn">

                        <div className="tbn">
                            <h2>Accomplishments</h2>

                            <div className="container obn">

                                <div className="tbn">


                                    <h4>Title:</h4>
                                    <JoditEditor ref={editor}
                                        value={content}
                                        tabIndex={1}
                                        // tabIndex of textarea
                                        onBlur={
                                            newContent => setContent(newContent)
                                        }
                                        // preferred to use only this option to update the content for performance reasons
                                        onChange={
                                            newContent => {}
                                        }/>

                                   

                                    <button className="btn btn-success w-100 mb-3"
                                        onClick={
                                            () => upload()
                                    }>
                                        Upload
                                    </button>
                                </div>

                            </div>
                            <Row> {
                                arrivals.map((p) => (
                                    <Col xs={12}
                                        md={12}
                                        lg={12}>
                                        <div className="card-b">
                                          
                                        
                                            <p className="p-3">
                                                {
                                                parse(p.content)
                                            }</p>
                                            <Button className="btn btn-danger mt-1"
                                                onClick={
                                                    () => removeProduct(p.uid, p.url, "newarrivals")
                                            }>
                                                X Remove
                                            </Button>
                                        </div>
                                    </Col>
                                ))
                            } </Row>
                        </div>
                        <hr/>

                    </div>
                </div>
            )
        } </div>
    );
};
export default AddBlogs;

import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Parallax, ParallaxProvider, useParallax} from 'react-scroll-parallax';
import './styles.css';
import {FaArrowCircleUp, FaBullseye} from 'react-icons/fa';
const About = () => {


    return (
        <div className='pt-2 pb-4 p-2' id='objectives'>

          
                    <Container className='abtcon p-0'>
                        <Row>
                            <Col lg={3}
                                xs={12}>
                                <div className='con2 p-5'>

                                    <FaBullseye className='' color='#916f5d'
                                        size={55}/>

                                    <h4 className="dark-color2 text-center mt-2">Objective</h4>

                                </div>
                            </Col>
                            <Col lg={9}
                                xs={12}>
                                <div>
                                    <p className='a-left dark-color p-5 justify'>
                                        Business re-design and re-structuring
                                                                                Finance & Investment consultancy
                                                                                Value engineering & strategic planning
                                                                                Digital transformation and Deployment
                                                                                Governance & Risk Management
                                                                                Project, Program and Service Management
                                                                            .</p>
                                </div>
                            </Col>


                        </Row>
                    </Container>
                

        </div>
    )
}

export default About

import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Parallax, ParallaxProvider, useParallax} from 'react-scroll-parallax';

import './styles.css';
import {
    FaArrowCircleUp,
    FaBullseye,
    FaCertificate,
    FaIdBadge,
    FaMedal,
    FaRegIdBadge,
    FaStar
} from 'react-icons/fa';
import firebase from "../../firebase";
import parse from 'html-react-parser';
import healthcareLogo1 from '../../assets/Accomplishments/Healthcare/Aurasence.png';
import healthcareLogo2 from '../../assets/Accomplishments/Healthcare/Merakkee.png';
import healthcareLogo3 from '../../assets/Accomplishments/Healthcare/Novacare.png';
import healthcareLogo4 from '../../assets/Accomplishments/Healthcare/Novadentel.png';
import EducationLogo1 from '../../assets/Accomplishments/Education/Suxseed.png';
import EducationLogo2 from '../../assets/Accomplishments/Education/Suxcellent.png';
import EducationLogo3 from '../../assets/Accomplishments/Education/London-british-nursery.png';
import EducationLogo4 from '../../assets/Accomplishments/Education/BriteBoard.png';
import HospitalityLogo1 from '../../assets/Accomplishments/Hospitality/Ashirvaad.jpg';
import HospitalityLogo2 from '../../assets/Accomplishments/Hospitality/Freshoppe.jpg';
import HospitalityLogo3 from '../../assets/Accomplishments/Hospitality/Restolita.png';
import HospitalityLogo4 from '../../assets/Accomplishments/Hospitality/unifreshfoods.jpg';
import HospitalityLogo5 from '../../assets/Accomplishments/Hospitality/yeS Baik.png';
import ContractingLogo1 from '../../assets/Accomplishments/Contracting/Invicta Group.png';
import ContractingLogo2 from '../../assets/Accomplishments/Contracting/tekzone.svg';
import IntegratingPlatforms1 from '../../assets/Accomplishments/Integrating Platforms/Grohood.png';
import IntegratingPlatforms2 from '../../assets/Accomplishments/Integrating Platforms/SAFE Logo.png';
import IntegratingPlatforms3 from '../../assets/Accomplishments/Integrating Platforms/bookit.png';
import IntegratingPlatforms4 from '../../assets/Accomplishments/Integrating Platforms/cure AE.png';
import IntegratingPlatforms5 from '../../assets/Accomplishments/Integrating Platforms/cure shop.png';
import Manufacturing1 from '../../assets/Accomplishments/Manufacturing/Glovelux.PNG';

const Accomplishments = () => {
    const [arrivals, setArrivals] = useState([]);
    useEffect(() => {
        fetchArrivals();
    }, []);

    const fetchArrivals = async () => {
        try {
            var datas = [];
            var snapshot = await firebase.firestore().collection("accomplishments").orderBy('date' ,'desc').get();
            snapshot.forEach((doc) => {
                datas.push(doc.data());
            });
            setArrivals([... datas]);
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <div className='pt-2 pb-2' id='accomplishments'>

          
                    <Container className='p-2'>

                        <div className='xpcon p-5 mt-3'>


                            <div>
                                <Row>
                                    <Col lg={1}
                                        xs={2}><FaMedal className='' color='#916f5d'
                                            size={35}/></Col>
                                    <Col>
                                        <h4 className="dark-color2 a-left">Accomplishments</h4>
                                    </Col>
                                </Row>
                                <br/>
                                
                                <Row lg={12} xs={12} className="text-center">
                                    <h4 className="dark-color2 a-left">Education</h4>
                                    <Row className="logo-container p-0 m-0">
                                        <Col lg={2} xs={4}><img src={EducationLogo1} alt="Education Logo 1" /></Col>
                                        <Col lg={2} xs={4}><img src={EducationLogo2} alt="Education Logo 2" /></Col>
                                        <Col lg={2} xs={4}><img src={EducationLogo3} alt="Education Logo 4" /></Col>
                                        <Col lg={2} xs={4}><img src={EducationLogo4} alt="Education Logo 3" /></Col>
                                        {/* Add more logos here */}
                                    </Row>
                                </Row>
                                <Row lg={12} xs={12} className="text-center">
                                    <h4 className="dark-color2 a-left pt-2">Healthcare</h4>
                                    <Row className="logo-container p-0 m-0">
                                        <Col lg={2} xs={4}><img src={healthcareLogo1} alt="Healthcare Logo 1" /></Col>
                                        <Col lg={2} xs={4}><img src={healthcareLogo2} alt="Healthcare Logo 2" /></Col>
                                        <Col lg={2} xs={4}><img src={healthcareLogo3} alt="Healthcare Logo 4" /></Col>
                                        <Col lg={2} xs={4}><img src={healthcareLogo4} alt="Healthcare Logo 3" /></Col>
                                        {/* Add more logos here */}
                                    </Row>
                                </Row>
                                <Row lg={12} xs={12} className="text-center">
                                    <h4 className="dark-color2 a-left pt-2">Hospitality</h4>
                                    <Row className="logo-container p-0 m-0">
                                        <Col lg={2} xs={4}><img src={HospitalityLogo1} alt="Hospitality Logo 1" /></Col>
                                        <Col lg={2} xs={4}><img src={HospitalityLogo2} alt="Hospitality Logo 2" /></Col>
                                        <Col lg={2} xs={4}><img src={HospitalityLogo4} alt="Hospitality Logo 4" /></Col>
                                        <Col lg={2} xs={4}><img src={HospitalityLogo3} alt="Hospitality Logo 3" /></Col>
                                        <Col lg={2} xs={4}><img src={HospitalityLogo5} alt="Hospitality Logo 5" /></Col>
                                        {/* Add more logos here */}
                                    </Row>
                                </Row>

                                <Row lg={12} xs={12} className="text-center">
                                    <h4 className="dark-color2 a-left pt-2">Integrating Platforms</h4>
                                    <Row className="logo-container p-0 m-0">
                                        <Col lg={2} xs={4}><img src={IntegratingPlatforms1} alt="Integrating Platforms Logo 1" /></Col>
                                        <Col lg={2} xs={4}><img src={IntegratingPlatforms2} alt="Integrating Platforms Logo 2" /></Col>
                                        <Col lg={2} xs={4}><img src={IntegratingPlatforms3} alt="Integrating Platforms Logo 3" /></Col>
                                        <Col lg={2} xs={4}><img src={IntegratingPlatforms4} alt="Integrating Platforms Logo 4" /></Col>
                                        <Col lg={2} xs={4}><img src={IntegratingPlatforms5} alt="Integrating Platforms Logo 5" /></Col>
                                        {/* Add more logos here */}
                                    </Row>
                                </Row>

                                <Row lg={12} xs={12} className="text-center">
                                    <h4 className="dark-color2 a-left pt-2">Contracting</h4>
                                    <Row className="logo-container p-0 m-0">
                                        <Col lg={2} xs={4}><img src={ContractingLogo1} alt="Contracting Logo 1" /></Col>
                                        <Col lg={2} xs={4}><img src={ContractingLogo2} alt="Contracting Logo 2" /></Col>
                                        {/* Add more logos here */}
                                    </Row>
                                </Row>

                                <Row lg={12} xs={12} className="text-center">
                                    <h4 className="dark-color2 a-left pt-2">Manufacturing</h4>
                                    <Row className="logo-container p-0 m-0">
                                        <Col lg={2} xs={4}><img src={Manufacturing1} alt="Manufacturing Logo 1" /></Col>
                                        {/* Add more logos here */}
                                    </Row>
                                </Row>


                                <hr/>
                               
                                <div style={{overflowX:'scroll'}}> {
                                arrivals.map((p) => (
                                    
                                        <div className="trow">
                                          
                                        
                                            <p className="p-1">
                                                {
                                                parse(p.content)
                                            }</p>
                                            
                                        </div>
                                   
                                ))
                            } </div>

                            </div>

                        </div>

                    </Container>
              

        </div>
    )
}

export default Accomplishments;

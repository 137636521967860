import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyAV_jn62CzGCC82LuLsxUg2OjYcMiQYkjo",
  authDomain: "robinkurianportfolio.firebaseapp.com",
  projectId: "robinkurianportfolio",
  storageBucket: "robinkurianportfolio.appspot.com",
  messagingSenderId: "785391281707",
  appId: "1:785391281707:web:aa14ce97bd87627be2ac6e",
  measurementId: "G-NCD15RNZZC"
};
firebase.initializeApp(config);

export default firebase;

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Home";
import ScrollToTop from './components/ScrollToTop'
import Login from "./Admin/Login/";
import AddBlogs from "./Admin/AddBlogs";


const Entrypoint = () => {
    return (
        <Router>
            <ScrollToTop/>
            <Routes>
                <Route exact path="/"
                    element={<Home/>}/>

                <Route exact path="/Admin_login"
                    element={<Login/>}/>
                <Route exact path="/Add_blogs"
                    element={<AddBlogs/>}/>

            </Routes>
        </Router>
    );
};
export default Entrypoint;

import React from 'react'
import {Container, Nav, Navbar} from 'react-bootstrap'
import './styles.css';
import Icon from '../../assets/icon2.png';
import {FaEnvelope, FaPhone, FaPhoneAlt} from 'react-icons/fa';

const NavBar = () => {
    return (
        <div>
            <Navbar style={
                    {background: 'none'}
                }
                sticky='top'
                expand='lg'
                className='p-0'>
                <Container fluid className='nav-bg'>
                    <Navbar.Brand href="#home"><img src={Icon}
                            width={100}
                            className='p-0'/></Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav"/> */}
                    {/* <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <a href="tel:+919747894662" className='nv'>
                                <FaPhoneAlt style={{marginRight:5}}/>
                                +91 9747894662</a>

                            <a href="mailto:robin@robinkurian.net" className='nv'><FaEnvelope style={{marginRight:5}}/>
                                robin@robinkurian.net</a>


                        </Nav>
                    </Navbar.Collapse> */}
                
                    <button className='btn' style={{marginTop:65,marginRight:35,backgroundColor:'#6e5548',color:'#fff'}}>My Profile</button>
                </Container>
            </Navbar>
        </div>
    )
}

export default NavBar

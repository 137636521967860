import React from 'react'
import './footer.css';
import {Col, Row} from 'react-bootstrap';
import {
    FaEnvelope,
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaPhoneAlt,
    FaTwitter
} from 'react-icons/fa';
import Logo from '../../assets/icon2.png';
import {BrowserView, MobileOnlyView} from 'react-device-detect';

const Footer = () => {
    return (
        <div id='footer'>
            <BrowserView >
          
            <Row className='foot1 m-0'>
                <Col lg={3}>

                    <div className='fcard1'>
                        <FaTwitter size={25}
                            style={
                                {marginRight: 15}
                            }/>

                        <FaLinkedin size={25}
                            style={
                                {marginRight: 15}
                            }/>

                        <FaFacebook size={25}
                            style={
                                {marginRight: 15}
                            }/>

                        <FaInstagram size={25}
                            style={
                                {marginRight: 15}
                            }/>
                        <hr/>
                        <span>
                            <FaEnvelope size={20}
                                style={
                                    {marginRight: 5}
                                }/>

                            robin@robinkurian.net
                        </span>
                    </div>


                </Col>
                <Col lg={6}>
             
                        <div className='fcard'>
                            <img src={Logo}
                                width={60}/><br/>
                            <p className=''>© 2023 Robin Kurian. All Rights Reserved</p>
                        </div>
                   
                </Col>

                <Col lg={3}>

                    <div className='fcard2'>
                        <Row>
                            <Col lg={1}
                                xs={2}/>
                            <Col lg={1}
                                xs={1}>
                                <FaPhoneAlt size={35}
                                    style={
                                        {margin: 10}
                                    }/>
                            </Col>
                            <Col lg={9}
                                xs={6}>
                                +91 9747894662<br/>
                                +971 5088 72712</Col>
                        </Row>

                    </div>


                </Col>
            </Row>
            </BrowserView>
            <MobileOnlyView >
            <Row className='foot2 m-0'>
                <Col lg={3}>

                    <div className='fcard1'>
                        <FaTwitter size={25}
                            style={
                                {marginRight: 15}
                            }/>

                        <FaLinkedin size={25}
                            style={
                                {marginRight: 15}
                            }/>

                        <FaFacebook size={25}
                            style={
                                {marginRight: 15}
                            }/>

                        <FaInstagram size={25}
                            style={
                                {marginRight: 15}
                            }/>
                        <hr/>
                        <span>
                            <FaEnvelope size={20}
                                style={
                                    {marginRight: 5}
                                }/>

                            robin@robinkurian.net
                        </span>
                    </div>


                </Col>
                <Col lg={6}>
                   
                        <center>
                            <div className='fcardb'>
                                <img src={Logo}
                                    width={60}/><br/>
                                <p className=''>© 2023 Robin Kurian. All Rights Reserved</p>
                            </div>
                        </center>
                   
                </Col>

                <Col lg={3}>

                    <div className='fcard2'>
                        <Row>
                            <Col lg={1}
                                xs={2}/>
                            <Col lg={1}
                                xs={1}>
                                <FaPhoneAlt size={35}
                                    style={
                                        {margin: 10}
                                    }/>
                            </Col>
                            <Col lg={9}
                                xs={6}>
                                +91 9747894662<br/>
                                +971 5088 72712</Col>
                        </Row>

                    </div>


                </Col>
            </Row>
            </MobileOnlyView>
         

        </div>
    )
}

export default Footer

import React from 'react'
import './styles.css';
import {
    FaGithub,
    FaLinkedin,
    FaInstagram,
    FaPhone,
    FaPhoneAlt,
    FaEnvelope,
    FaPhoneSquare,
    FaPhoneSquareAlt,
    FaTwitter,
    FaFacebook
} from 'react-icons/fa';
import {Col, Row} from 'react-bootstrap';
import AnimatedText from 'react-animated-text-content';
import About from '../Objectives';
import {BrowserView, MobileOnlyView} from 'react-device-detect';
import Profile from '../../assets/Group2.png';
import Lap from '../../assets/lap.png';
const Section1 = (props) => {
    return (
        <div className='section1'>
            <div className='sub-section container p-5'>
                <Row>
                    <Col lg={8}
                        xs={12}>

                        <h6 className='pink-color mt-2'>
                            <AnimatedText animationType="float"
                                interval={0.06}
                                duration={0.3}>Business Optimization Specialist & Consultant</AnimatedText>
                        </h6>
                        <h1 className='dark-color2 bold mt-0'>
                            <AnimatedText animationType="lights"
                                interval={0.06}
                                duration={1}>ROBIN KURIAN</AnimatedText>
                        </h1>
                        <MobileOnlyView>

                            <div className='img-con'>
                                <center>
                                    <img src={Profile}
                                        className='profile w-75'/>
                                </center>
                            </div>

                            <Row className='mt-2'>
                                <Col xs={2}></Col>
                                <Col xs={2}>
                                    <center>
                                        <FaTwitter size={35}
                                            color='#6e5548'
                                            style={
                                                {marginRight: 15}
                                            }/></center>
                                </Col>
                                <Col xs={2}>
                                    <center>
                                        <FaLinkedin size={35}
                                            color='#6e5548'
                                            style={
                                                {marginRight: 15}
                                            }/></center>
                                </Col>
                                <Col xs={2}>
                                    <center>
                                        <FaFacebook size={35}
                                            color='#6e5548'
                                            style={
                                                {marginRight: 15}
                                            }/></center>
                                </Col>
                                <Col xs={2}>
                                    <center>
                                        <FaInstagram size={35}
                                            color='#6e5548'
                                            style={
                                                {marginRight: 15}
                                            }/></center>
                                </Col>
                                <Col xs={2}></Col>
                            </Row>

                        </MobileOnlyView>
                        <p className='grey-color mt-3 justify'>
                            I am a business and systems consultant with more than 20 years of experience working in different domains, including Medical, Hospitality, Education, and Retail/E-Commerce. As a specialist in business management, I focus on matching the right systems and business services to achieve optimum effectiveness. My area of expertise entails giving management advice on budgeting, planning, and implementation procedures for various departments.
                            <br/><br/>
                            My main objective is to impart my knowledge and expertise to firms so they can convert in an inventive way and develop their business processes.</p>
                        <a class="custom-btn btn-7" href='#footer'>
                            <span>Get In Touch</span>
                        </a>

                    </Col>
                    <Col></Col>
                    <Col lg={3}
                        xs={12}>
                        <center>
                            <BrowserView>
                                <div className='img-con'><img src={Profile}
                                        className='profile'/>
                                </div>
                                <Row className='mt-2'>
                                    <Col></Col>
                                    <Col lg={2}>
                                        <FaTwitter size={35}
                                            color='#6e5548'
                                            style={
                                                {marginRight: 15}
                                            }/>
                                    </Col>
                                    <Col lg={2}>
                                        <FaLinkedin size={35}
                                            color='#6e5548'
                                            style={
                                                {marginRight: 15}
                                            }/>
                                    </Col>
                                    <Col lg={2}>
                                        <FaFacebook size={35}
                                            color='#6e5548'
                                            style={
                                                {marginRight: 15}
                                            }/>
                                    </Col>
                                    <Col lg={2}>
                                        <FaInstagram size={35}
                                            color='#6e5548'
                                            style={
                                                {marginRight: 15}
                                            }/>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </BrowserView>
                        </center>
                    </Col>

                </Row>
            </div>
        </div>
    )
}

export default Section1;
